import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Img from 'gatsby-image';
import Section from 'components/section';

const Image = ({ image }) => (
  <Img
    fluid={image ? image.childImageSharp.fluid : {}}
    imgStyle={{ objectFit: 'cover' }}
    style={{ height: '100%', maxHeight: '100%' }}
  />
);

Image.propTypes = {
  image: PropTypes.object.isRequired,
};

const Services = ({ data }) => (
  <Layout>
    <Head pageTitle={data.servicesJson.title} />
    <Box spaced center>
      <Section>
        <div
          dangerouslySetInnerHTML={{
            __html: data.servicesJson.content.childMarkdownRemark.html,
          }}
        />
      </Section>
    </Box>
  </Layout>
);

Services.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Services;

export const query = graphql`
  query ServicesQuery {
    servicesJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
